import { graphql, navigate, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Buttons from "../components/common/button"
import { sendEventHumioLog } from "../components/common/FormBuilder/Form-Function"
import Frame from "../components/common/frame"
import Testimonial from "../components/common/testimonial-component/testimonial"
import Navigation from "../components/navigation"
import CTAThankYou from "../components/utility/cta_thank_you"
import GetSVG from "../components/utility/get-svg"
import Slide from "../components/utility/slide_content"
import Close from "./../assets/images/close-white.png"

// import "./../../static/chillipiper"
const DemoPage = ({ location }) => {
  const [showForm, setShowForm] = useState(false)

  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      value:
        (typeof sessionStorage !== "undefined" &&
          sessionStorage.getItem("firstname")) ||
        "",
      errorMessage: "Please enter a name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      value:
        (typeof sessionStorage !== "undefined" &&
          sessionStorage.getItem("email")) ||
        "",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      value:
        (typeof sessionStorage !== "undefined" &&
          sessionStorage.getItem("company")) ||
        "",
      errorMessage: "Please enter a company name",
    },

    {
      name: "mobilephone",
      type: "phone",
      label: "Mobile number",
      value:
        (typeof sessionStorage !== "undefined" &&
          sessionStorage.getItem("mobilephone")) ||
        null,
      errorMessage: "Please enter a mobile number",
    },

    {
      name: "current_tool_set",
      type: "select",
      label: "Choose what you currently use",
      value: null,
      choices: [
        { label: "Datto", value: "Datto" },
        { label: "NinjaRMM", value: "NinjaRMM" },
        { label: "Kaseya", value: "Kaseya" },
        { label: "Atera", value: "Atera" },
      ],
      errorMessage: "Please select a value",
    },
  ]

  const handleAfterSubmit = (res, items) => {
    let toolset = items.filter(el => el.name === "current_tool_set")[0].value
    if (toolset.includes("Datto") || toolset.includes("Autotask")) {
      navigate(`/thank-you?src=demo`, { state: { showVideo: true } })
    } else {
      function q(a) {
        return function() {
          window.ChiliPiper[a].q = (window.ChiliPiper[a].q || []).concat([
            arguments,
          ])
        }
      }

      window.ChiliPiper =
        window.ChiliPiper ||
        "submit scheduling showCalendar submit widget bookMeeting"
          .split(" ")
          .reduce(function(a, b) {
            a[b] = q(b)
            return a
          }, {})

      window.ChiliPiper &&
        window.ChiliPiper.submit("superops", "inbound-router", {
          map: false,
          onSuccess: function chilipiperSuccess() {
            sendEventHumioLog({}, `Demo scheduled Succes`)
            window.location.href = `/thank-you?src=demo`
          },

          lead: {
            firstname: `${sessionStorage.getItem("firstname")}`,
            lastname: `${sessionStorage.getItem("lastname")}`,
            email: `${sessionStorage.getItem("email")}`,
            company: `${sessionStorage.getItem("company")}`,
            phone: `${sessionStorage.getItem("phone")}`,
          },
          onError: function chilipiperError() {
            sendEventHumioLog(`Chilipiper Error`)
          },
          onClose: function userClosed() {
            sendEventHumioLog(
              `User submitted form and closed without scheduling`
            )
          },
        })
    }
  }

  useEffect(() => {
    if (document.referrer != "") {
      setShowForm(true)
    }
  }, [])

  return (
    <StaticQuery
      query={graphql`
        query contentDemoPage {
          SuperOps {
            pages(where: { title: "Demo page" }) {
              title
              pageBlock {
                id
                content {
                  html
                }
              }
              navigationBlock {
                name
                slug
                isDropdown
              }
              seo {
                title
                description
                keywords
                image {
                  url
                }
              }
            }
            peopleList(where: { page_contains: "Home" }) {
              page
              name
              title
              testimonial
              background
              cardBgColor
              thumbnail {
                url(
                  transformation: {
                    document: { output: { format: webp } }
                    image: { resize: { width: 110 } }
                  }
                )
              }
              picture {
                url(
                  transformation: {
                    document: { output: { format: webp } }
                    image: { resize: { width: 500 } }
                  }
                )
              }
              titleText {
                html
              }
              descriptionText {
                html
              }
            }
          }
        }
      `}
      render={data => (
        <div className="contact-us demoPage">
          {data.SuperOps.pages.map(page => {
            const { title, pageBlock, navigationBlock, seo } = page
            return (
              <>
                <Frame
                  title={seo?.title}
                  description={seo?.description}
                  keywords={seo?.keywords}
                  ogTitle={seo?.title}
                  ogDescription={seo?.description}
                  ogImage={seo?.image}
                  ogType="website"
                  footerClassName="demoPage"
                  hubspotMeeting
                  demoForm
                >
                  <header>
                    <Navigation color={"#EAF0FF"} page={"Public Beta"} />
                  </header>

                  <section className="hero">
                    <Container className="demoPage-container">
                      <Row>
                        <Col lg={6}>
                          <div className="cn-left">
                            <Slide delay="200">
                              <div className="alt">
                                <h1 className="active">demo page</h1>
                                {parse(pageBlock[0].content.html)}
                              </div>
                            </Slide>
                            <div className="details">
                              <Slide delay="300">
                                <div className="altv2 p18">
                                  {parse(pageBlock[1].content.html)}
                                </div>
                              </Slide>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <Slide delay="700">
                            <div className="cn-form">
                              <center>
                                <section className="cn-cta bts-cta-cnt">
                                  <Container className="position-relative">
                                    <span className="info">
                                      <center>
                                        <GetSVG
                                          name="calender"
                                          className="mb-4 mt-2"
                                        />
                                        <div className="p24 ">
                                          {parse(pageBlock[3].content.html)}
                                        </div>
                                        <Buttons
                                          theme="primary w-100"
                                          text="BOOK A DEMO"
                                          onClick={() => setShowForm(true)}
                                        />
                                      </center>
                                    </span>
                                    {/* <FormBuilder
                                      id="demo-form"
                                      formClassName="forms"
                                      errorClassName="error-message"
                                      inputErrorClassName="error-active"
                                      data={formdata}
                                      buttonText="SUBMIT"
                                      buttonClassName="primary"
                                      buttonId="demoSubmitButton"
                                      IpStackData
                                      endpoint={
                                        process.env.HUBSPOT_DEMO_ENDPOINT
                                      }
                                      url={process.env.HUBSPOT_DEMO_URL}
                                      formName="Demo page - Superops"
                                      sessionStorage
                                      afterSubmit={(res, items) =>
                                        handleAfterSubmit(res, items)
                                      }
                                    /> */}
                                  </Container>
                                </section>
                              </center>
                            </div>
                          </Slide>
                        </Col>
                      </Row>
                    </Container>
                  </section>
                  <section className="testimonial-section">
                    <Container>
                      <div className="position-relative">
                        <Testimonial
                          type="multiple-1"
                          transparent={true}
                          items={data.SuperOps.peopleList.filter(
                            el => el.page === "Home"
                          )}
                        />
                      </div>
                    </Container>
                  </section>

                  <div
                    className="overlay"
                    style={{ display: showForm ? "block" : "none" }}
                  >
                    <img
                      src={Close}
                      className="close-icn"
                      alt="close"
                      onClick={() => setShowForm(false)}
                    />
                    <div className="position-relative">
                      <div
                        class="meetings-iframe-container"
                        data-src="https://meetings.hubspot.com/arjun-marella/introduction-to-superopsai?embed=true"
                      />
                    </div>
                  </div>

                  <CTAThankYou />
                </Frame>
              </>
            )
          })}
        </div>
      )}
    />
  )
}

export default DemoPage

// HUBSPOT SUCCESS URL : https://superops.ai/demo/success?__hstc=20629287.65378e71cc0d7a53712c8dc264dbafda.1640597208426.1640597208426.1640597208426.1&__hssc=20629287.1.1640597208424&__hsfp=3203040867
